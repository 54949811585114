<template>
  <b-card-text>
    <div class="mb-50">
      From <strong>{{ dataEscalate.author }}</strong> at {{ dataEscalate.created_at | formatDate }}<br>
      To <strong>{{ dataEscalate.expert_group }}</strong>
    </div>
    <h6 class="fw-bold mb-50">
      Message:
    </h6>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="mb-50"
      v-html="dataEscalate.message"
    />
    <!-- eslint-enable -->
    <h6 class="mt-1 mb-50">
      Discussions
    </h6>
    <b-list-group
      v-if="dataEscalate.discussions.length !== 0"
      class="mb-1"
    >
      <b-list-group-item
        v-for="disc in dataEscalate.discussions"
        :key="disc.esc_resp_id"
      >
        <div class="small mb-50">
          <strong>{{ disc.author_name }}</strong> at {{ disc.created_at | formatDate }}<br>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="disc-message w-75"
          v-html="disc.message"
        />
        <!-- eslint-enable -->
      </b-list-group-item>
    </b-list-group>
    <p v-else>
      <i>No discussions yet.</i>
    </p>
    <template
      v-if="userData.group_id !== 90 && dataEscalate.status !== 'Closed'"
    >
      <h6 class="mt-1 mb-50">
        Reply
      </h6>
      <div class="mb-50">
        <quill-editor
          id="escReplyQuill"
          ref="escReplyQuill"
          v-model="escReply"
          class="editor"
          :options="editorOption2"
        />
        <div
          id="quill-toolbar-side2"
          class="d-flex border-top-0"
        >
          <!-- Add a bold button -->
          <button class="ql-bold" />
          <button class="ql-italic" />
          <button class="ql-underline" />
          <button class="ql-link" />
        </div>
      </div>
      <template v-if="alert">
        <b-alert
          :variant="alertDetail.variant"
          class="m-1"
          show
        >
          <h4 class="alert-heading">
            {{ alertDetail.title }}
          </h4>
          <div
            v-if="alertDetail.variant === 'danger'"
            class="alert-body"
          >
            <ul class="pl-2">
              <li
                v-for="(item, index) in alertDetail.text"
                :key="index"
              >
                <template v-for="txt in item">
                  {{ txt }}
                </template>
              </li>
            </ul>
          </div>
          <div
            v-else
            class="alert-body"
          >
            {{ alertDetail.text }}
          </div>
        </b-alert>
      </template>
      <div class="mb-50">
        <b-button
          variant="primary"
          size="sm"
          class="mb-50 mr-50"
          @click="saveEscalationReply(dataEscalate)"
        >
          Save Reply
        </b-button>
        <b-button
          variant="warning"
          size="sm"
          class="mb-50 float-right"
          @click="closeEscalation(dataEscalate)"
        >
          Close Escalation
        </b-button>
      </div>
    </template>
  </b-card-text>
</template>

<script>
import {
  BCardText, BButton, BAlert, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    BButton,
    BAlert,
    BListGroup,
    BListGroupItem,
    quillEditor,
  },
  props: {
    eskalasi: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  data() {
    return {
      userData: getUserData(),
      escReply: '',
      dataEscalate: {
        discussions: [],
      },
      editorOption2: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar-side2',
        },
      },
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  mounted() {
    this.openEscalation()
  },
  methods: {
    openEscalation() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        esc_id: this.eskalasi,
      }

      this.$http.post('escalate/view', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.dataEscalate = resp.data.data
            this.alert = false
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating escalation reply',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveEscalationReply(disc) {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        esc_id: disc.esc_id,
        message: this.escReply,
      }

      this.$http.post('escalate/reply', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Escalation reply saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })

            const newResp = resp.data.data
            newResp.author_name = this.userData.fullname

            this.dataEscalate.discussions.push(newResp)
            this.escReply = ''
            this.alert = false
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating escalation reply',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeEscalation(disc) {
      this.$swal({
        title: 'Close This Escalation?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save and close!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          document.getElementById('loading-bg').style.display = 'block'
          const target = '/escalate/close'
          const params = {
            esc_id: disc.esc_id,
            status_id: 2,
          }
          this.$http.post(target, params, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                document.getElementById('loading-bg').style.display = 'none'
                this.$swal({
                  icon: 'success',
                  title: 'Update Completed!',
                  text: 'Escalation status has been updated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.$emit('closemodal')
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(errors => {
              document.getElementById('loading-bg').style.display = 'none'
              this.toast({
                component: ToastificationContent,
                props: {
                  title: `Error Updating Data : ${errors.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.$emit('escalate')
          this.$swal.close()
        }
      })
    },
  },
}
</script>

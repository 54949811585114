<template>
  <div class="sidebar-right">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            id="right-side-box"
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <app-collapse>
              <app-collapse-item
                title="Details"
                :is-visible="true"
              >
                <div class="mb-50">Ticket ID<br>
                  <small>{{ message.ticket_number }}</small>
                </div>
                <div class="mb-50">Source<br>
                  <small>{{ message.channel }}</small>
                </div>
                <div class="mb-50">Created<br>
                  <small>{{ message.created_at | formatDate }}</small>
                </div>
                <div class="mb-50">Last update<br>
                  <small>{{ message.updated_at | formatDate }}</small>
                </div>
                <div class="mb-50">Status<br>
                  <v-select
                    v-if="message.status !== 'Spam' && message.status !== 'New Ticket' && userData.group_id !== 90 && userData.group_id < 1000"
                    v-model="message.status"
                    :options="reference.status"
                    class="small"
                    :reduce="val => val.name"
                    label="name"
                    placeholder="Status"
                    @input="queue"
                  />
                  <small v-else>{{ message.status }}</small>
                </div>
                <div class="mb-50">Priority<br>
                  <v-select
                    v-if="message.status !== 'Spam' && message.status !== 'New Ticket' && userData.group_id !== 90 && userData.group_id < 1000"
                    v-model="message.priority"
                    :options="reference.priority"
                    class="small"
                    :reduce="val => val.name"
                    label="name"
                    placeholder="Priority"
                    @input="queue"
                  />
                  <small v-else>{{ message.priority }}</small>
                </div>
                <div class="mb-50">Agent<br>
                  <v-select
                    v-if="message.status !== 'Spam' && message.status !== 'New Ticket' && userData.group_id !== 90 && userData.group_id < 1000"
                    v-model="message.agent"
                    :options="reference.officer"
                    class="small"
                    :reduce="val => val.fullname"
                    label="fullname"
                    placeholder="Agent"
                    @input="queue"
                  />
                  <small v-else>{{ message.agent }}</small>
                </div>
                <div class="mb-50">Tag<br>
                  <v-select
                    v-if="message.status !== 'Spam' && message.status !== 'New Ticket' && userData.group_id !== 90 && userData.group_id < 1000"
                    v-model="message.tag"
                    :options="reference.tags"
                    class="small"
                    label="name"
                    placeholder="Tag"
                    @input="updateTags"
                  />
                  <template v-else>
                    <b-badge
                      v-for="(label) in message.tags"
                      :key="label.name"
                      pill
                      class="text-capitalize mr-50"
                      variant="light-primary"
                    >
                      {{ label.name }}
                    </b-badge>
                  </template>
                </div>
                <div
                  v-if="message.tagging_id !== 0"
                  class="mb-50"
                >Sub Tag<br>
                  <v-select
                    v-if="message.status !== 'Spam' && message.status !== 'New Ticket' && userData.group_id !== 90 && userData.group_id < 1000"
                    v-model="message.subtag"
                    :options="subTags"
                    class="small"
                    label="name"
                    placeholder="Sub Tag"
                    required
                    @input="updateSubTags"
                  />
                  <template v-else>
                    <b-badge
                      v-for="(label) in message.tags"
                      :key="label.name"
                      pill
                      class="text-capitalize mr-50"
                      variant="light-primary"
                    >
                      {{ label.name }}
                    </b-badge>
                  </template>
                </div>

                <div
                  v-if="queueUpdate"
                  class="form-group-compose text-center compose-btn"
                >
                  <b-button
                    v-if="userData.group_id !== 90"
                    variant="primary"
                    block
                    @click="updateData"
                  >
                    Save Update
                  </b-button>
                </div>
              </app-collapse-item>
              <app-collapse-item
                v-if="message.pse && message.pse.request_code !== ''"
                title="Data PSE"
                :is-visible="true"
              >
                <div class="mb-50">Email OSS<br>
                  <small>{{ message.pse.email_oss }}</small>
                </div>
                <div class="mb-50">NIB<br>
                  <small>{{ message.pse.nib }}</small>
                </div>
                <div class="mb-50">KBLI<br>
                  <small>{{ message.pse.kbli }}</small>
                </div>
                <div class="mb-50">ID Izin<br>
                  <small>{{ message.pse.id_izin }}</small>
                </div>
                <div class="mb-50">Kendala<br>
                  <small>{{ message.pse.message }}</small>
                </div>
                <div>Related Files</div>
                <div
                  v-if="message.pse.attachments && message.pse.attachments.length !== 0"
                  class="d-flex flex-column"
                >
                  <b-link
                    v-for="(atc, index) in message.pse.attachments"
                    :key="index"
                    :href="atc.fileurl"
                    target="_blank"
                    class="small"
                    :class="{'mt-25': index}"
                  >
                    {{ atc.filename }}
                  </b-link>
                </div>
              </app-collapse-item>
              <app-collapse-item
                title="Escalation"
                :is-visible="true"
              >
                <template v-if="message.escalations.length !== 0">
                  <div
                    v-for="esc in message.escalations"
                    :key="esc.esc_id"
                    class="px-0 pb-1 border-bottom mb-50 d-flex justify-content-between align-content-middle"
                  >
                    <div class="small">
                      <strong>[{{ esc.status }}]</strong><br>
                      By <strong>{{ esc.author }}</strong><br>
                      To <strong>{{ esc.expert_group }}</strong><br>
                      at {{ esc.created_at | formatDate }}
                    </div>

                    <b-button
                      variant="outline-info"
                      size="sm"
                      class="text-right"
                      @click="$emit('viewescalate', esc.esc_id)"
                    >
                      view
                    </b-button>
                  </div>
                </template>
                <div>
                  <b-button
                    v-if="userData.group_id === 100"
                    variant="outline-primary"
                    size="sm"
                    class="mb-50"
                    @click="newEscalation"
                  >
                    Add Escalation
                  </b-button>
                </div>
                <template
                  v-if="newEscalate"
                >
                  <template
                    v-if="escAble"
                  >
                    <hr>
                    <h6 class="d-flex justify-content-between align-items-center">
                      <span>New Escalation</span>
                      <b-button
                        variant="flat-danger"
                        size="sm"
                        class="text-right"
                        @click="newEscalation"
                      >
                        X
                      </b-button>
                    </h6>
                    <div class="mb-50">
                      <v-select
                        v-if="message.status !== 'Spam' && message.status !== 'New Ticket'"
                        v-model="escalate.group_id"
                        :options="escRef"
                        class="small"
                        :required="escalate.group_id !== ''"
                        :reduce="val => val.group_id"
                        label="group_name"
                        placeholder="Choose Expert Team"
                      />
                    </div>

                    <div class="mb-50">
                      <quill-editor
                        id="escQuill"
                        ref="escQuill"
                        v-model="escalate.message"
                        class="editor"
                        :options="editorOption"
                        :disabled="(escalate.group_id === '') ? true : false"
                      />
                      <div
                        id="quill-toolbar-side"
                        class="d-flex border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-link" />
                      </div>
                    </div>

                    <div
                      v-if="escalate.group_id"
                    >
                      <b-button
                        variant="primary"
                        size="sm"
                        class="mb-50"
                        @click="saveEscalation"
                      >
                        Save
                      </b-button>
                    </div>
                  </template>
                  <template v-else>
                    <b-alert
                      variant="danger"
                      class="m-1"
                      dismissible
                      show
                    >
                      <div class="alert-body">
                        Masih ada Eskalasi yang aktif, anda tidak dapat membuat eskalasi baru.
                      </div>
                    </b-alert>
                  </template>
                </template>
              </app-collapse-item>
            </app-collapse>
            <b-button
              variant="flat-secondary"
              size="lg"
              class="text-left p-1 my-0 border-bottom"
              block
              @click="$emit('openmodal', 'summary')"
            >Ticket Summary</b-button>
            <b-button
              variant="flat-secondary"
              size="lg"
              class="text-left p-1 my-0 border-bottom"
              block
              @click="$emit('openmodal', 'searchfaq')"
            >FAQ Search</b-button>
            <b-button
              variant="flat-secondary"
              size="lg"
              class="text-left p-1 my-0 border-bottom"
              block
              @click="$emit('openmodal', 'searchpse')"
            >PSE Search</b-button>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BButton, BLink, BBadge, BAlert,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useEmail from './useEmail'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    // BSV
    BButton,
    BLink,
    BBadge,
    BAlert,
    vSelect,
    VuePerfectScrollbar,
    quillEditor,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    reference: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast()
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveFilterBadgeColor = filter => {
      if (filter === 'Pending') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      toast,

      resolveFilterBadgeColor,
      perfectScrollbarSettings,

      // useTicket
      resolveLabelColor,
    }
  },
  data() {
    return {
      userData: getUserData(),
      queueUpdate: false,
      newEscalate: false,
      subTags: [],
      selectedAttach: [],
      escRef: [],
      escalate: {
        group_id: '',
        user_id: '',
        message: '',
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar-side',
        },
      },
      editorOption2: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar-side2',
        },
      },
      escAble: true,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    exitEsc() {
      this.$emit('escalate')
    },
    queue(value) {
      this.queueUpdate = true
      if (value === 'Closed') {
        this.$emit('closechange')
        this.queueUpdate = false
      }
    },
    updateTags(value) {
      if (value !== null && this.message.tagging_id !== value.tagging_id) {
        this.message.tagging_id = value.tagging_id
        this.message.subtag = null
        this.message.subtagging_id = 0
      }
      if (value !== null) {
        this.getSubTags()
      }
      this.queueUpdate = true
    },
    updateSubTags(value) {
      if (value !== null && this.message.subtagging_id !== value.subtagging_id) {
        this.message.subtagging_id = value.subtagging_id
        this.message.subtag = value
      }
      if (value !== null) {
        this.getSubTags()
      }
      this.queueUpdate = true
    },
    getSubTags() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        tagging_id: this.message.tagging_id,
      }
      this.$http.post('ref/stg', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.subTags = resp.data
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error getting Sub Tags',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateData() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        ticket_id: this.message.ticket_id,
        status_id: this.message.status,
        agent_id: this.message.agent,
        priority_id: this.message.priority,
        tagging_id: this.message.tagging_id,
        subtagging_id: this.message.subtagging_id,
        tags: this.message.tags,
      }
      this.$http.post('ticket/status', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          document.getElementById('loading-bg').style.display = 'none'
          if (resp.status === 200) {
            this.$emit('dataupdate', resp.data.data)
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Status Updated',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            this.queueUpdate = false
          }
        })
        .catch(e => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    newEscalation() {
      this.newEscalate = !(this.newEscalate)
      this.escRef = []

      const esc = this.message.escalations
      const expGrp = []
      esc.map(data => {
        if (data.status === 'Active') {
          if (!expGrp.includes(data.expert_group)) {
            expGrp.push(data.expert_group)
          }
        }
        return true
      })

      this.reference.group.map(grp => {
        if (!expGrp.includes(grp.group_name)) {
          this.escRef.push(grp)
        }
        return true
      })
      if (this.escRef.length !== 0) {
        this.escAble = true
      } else {
        this.escAble = false
      }
    },
    saveEscalation() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = this.escalate
      params.ticket_id = this.message.ticket_id

      if (this.escalate.message === '') {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Please Add Escalation Message',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        document.getElementById('loading-bg').style.display = 'none'
      } else {
        this.$http.post('escalate', params, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(resp => {
            document.getElementById('loading-bg').style.display = 'none'
            if (resp.status === 200) {
              this.$emit('dataupdate')
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Escalation Created',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              this.escalate = {
                group_id: '',
                user_id: '',
                message: '',
              }
              this.queueUpdate = false
            }
          })
          .catch(() => {
            document.getElementById('loading-bg').style.display = 'none'
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error creating escalation',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>

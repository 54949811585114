<template>
  <div class="email-app-details show">

    <!-- Ticket Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="goBack"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ ticketData.subject }}
        </h4>
      </div>
    </div>

    <!-- Ticket Details -->
    <vue-perfect-scrollbar
      id="response-box"
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Ticket Thread -->
      <b-row>
        <b-col
          cols="12"
          class="mt-1"
        >
          <ticket-message-main-card
            :message="ticketData"
            @edit-author="openModal('author')"
          />
        </b-col>
      </b-row>

      <!-- Earlier Ticket Messages -->
      <template v-if="ticketData.responses.length !== 0">
        <b-row
          v-for="threadMail in ticketData.responses"
          :key="threadMail.response_id"
        >
          <b-col cols="12">
            <ticket-message-card
              :ticket="ticketData"
              :message="threadMail"
              :user="userData"
              @add-faq="showAddFaqModal"
              @viewescalate="setEscalate"
            />
          </b-col>
        </b-row>
      </template>

      <!-- Closing Messages -->
      <template v-if="ticketData.status === 'Closed'">
        <b-row>
          <b-col cols="12">
            <b-card
              no-body
              class="w-75"
            >
              <b-card-header
                class="email-detail-head px-1 pb-50 pt-1"
              >
                <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
                  <div class="mail-items">
                    <h5 class="mb-0">
                      Summary
                      <b-link
                        v-if="userData.group_id !== 90 && userData.group_id < 1000"
                        class="small ml-1"
                        @click.prevent="openModal('summary')"
                      >Edit</b-link>
                    </h5>
                  </div>
                </div>
              </b-card-header>

              <b-card-body class="mail-message-wrapper p-1">
                <div class="mail-message">
                  <p><b>Tipe Interaksi</b><br>
                    {{ (ticketData.interaksi) ? ticketData.interaksi : '-' }}
                  </p>
                  <p><b>Kasus</b><br>
                    {{ (ticketData.kasus) ? ticketData.kasus : '-' }}
                  </p>
                  <p><b>Penanganan</b><br>
                    {{ (ticketData.penanganan) ? ticketData.penanganan : '-' }}
                  </p>
                  <p><b>Keterangan</b><br>
                    {{ (ticketData.keterangan) ? ticketData.keterangan : '-' }}
                  </p>
                  <p><b>Alasan</b><br>
                    {{ (ticketData.resume) ? ticketData.resume : '-' }}
                  </p>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </template>

    </vue-perfect-scrollbar>

    <div
      v-if="userData.group_id !== 90 && userData.group_id < 1000"
      id="reply-box"
      class="email-detail-footer"
    >
      <!-- Ticket Actions: Reply or Forward -->
      <template v-if="ticketData.status === 'New Ticket'">
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Click here to
                <b-button
                  size="sm"
                  variant="primary"
                  @click.prevent="updateStatus('accept')"
                >
                  Accept Ticket
                </b-button>
                or
                <b-button
                  size="sm"
                  variant="danger"
                  @click.prevent="updateStatus('spam')"
                >
                  Set as Spam
                </b-button>
              </h5>
            </div>
          </b-card>
        </b-col>
      </template>
      <template v-else-if="ticketData.status !== 'Spam' && ticketData.status !== 'Closed'">
        <b-col cols="12">
          <b-card class="mb-1">
            <quill-editor
              id="replyQuill"
              ref="myTextEditor"
              v-model="replyText"
              class="editor"
              :options="editorOption"
            />
            <div
              id="quill-toolbar"
              class="d-flex border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-link" />
              <button class="ql-emoji" />
              <b-button
                v-b-tooltip.hover.top="'Template Response'"
                v-b-modal.modal-template
                size="sm"
                variant="flat-info"
              >
                R
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Form Kendala PSE'"
                size="sm"
                variant="flat-info"
                @click="addKendala"
              >
                K
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Attach Documents'"
                size="sm"
                variant="flat-info"
                @click="viewAttach"
              >
                <feather-icon
                  icon="PaperclipIcon"
                  size="14"
                />
              </b-button>
            </div>
            <b-row
              v-if="attach"
              class="mt-50"
              align-v="center"
            >
              <b-col>
                <b-form-file
                  v-model="files"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  size="sm"
                  multiple
                />
              </b-col>
            </b-row>
            <b-row
              class="mt-50"
              align-v="center"
            >
              <b-col>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="saveReply"
                >
                  Send Reply
                </b-button>
                <b-button
                  size="sm"
                  variant="warning"
                  class="float-right"
                  @click="closeTicket"
                >
                  Close
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </template>
    </div>

    <portal to="content-renderer-sidebar-right">
      <ticket-right-sidebar
        :message="ticketData"
        :reference="reference"
        @closechange="closeTicket"
        @viewescalate="setEscalate"
        @dataupdate="updateData"
        @openmodal="openModal"
      />
    </portal>

    <!-- modal template -->
    <b-modal
      id="modal-template"
      ref="modal-template"
      title="Reply Templates"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <app-collapse
          v-if="templateAnswer.length !== 0"
          class="mb-1"
        >
          <app-collapse-item
            v-for="template in templateAnswer"
            :key="template.template_id"
            :title="template.title"
            class="px-0"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="small faq-content-detail"
              v-html="template.message"
            />
            <!-- eslint-enable -->
            <b-button
              variant="primary"
              size="sm"
              class="badge-round"
              @click="updateReply(template.message)"
            >
              Select Reply
            </b-button>
          </app-collapse-item>
        </app-collapse>
      </b-card-text>
    </b-modal>
    <!-- modal -->

    <!-- modal author -->
    <b-modal
      id="modal-author"
      ref="author"
      title="Update Customer Data"
      hide-footer
    >
      <ticket-author
        :ticket="ticketData"
        @dataupdate="updateData"
        @closemodal="closeModal"
      />
    </b-modal>

    <!-- modal summary -->
    <b-modal
      id="modal-summary"
      ref="summary"
      title="Ticket Summary"
      hide-footer
      @hide="getData"
    >
      <ticket-summary
        :interaksi="interaksi"
        :ticket="ticketData"
        :reference="reference"
        @dataupdate="updateData"
        @closemodal="closeModal"
      />
    </b-modal>

    <!-- modal pse -->
    <b-modal
      id="modal-pse"
      ref="searchpse"
      title="Search PSE Data"
      size="lg"
      hide-footer
    >
      <ticket-pse />
    </b-modal>

    <!-- modal search faq -->
    <b-modal
      id="modal-search-faq"
      ref="searchfaq"
      title="Search FAQ"
      size="lg"
      hide-footer
    >
      <ticket-faq
        @replyupdate="updateFaqReply"
        @replyattach="updateFaqAttach"
        @closemodal="closeModal('searchfaq')"
      />
    </b-modal>

    <!-- modal search faq -->
    <b-modal
      id="modal-escalate-detail"
      ref="escalatedetail"
      title="Escalation Detail"
      size="lg"
      hide-footer
      @hide="getData"
    >
      <ticket-escalate
        :eskalasi="escalate_id"
        @replyupdate="updateFaqReply"
        @replyattach="updateFaqAttach"
        @closemodal="closeModal('escalatedetail')"
      />
    </b-modal>

    <!-- modal faq -->
    <b-modal
      id="modal-faq"
      ref="modal-faq"
      title="Add FAQ Data"
      ok-title="Save"
      @ok.prevent="saveFaq"
    >
      <b-card-text>
        <!-- BODY -->
        <template v-if="alert">
          <b-alert
            :variant="alertDetail.variant"
            class="m-1"
            show
          >
            <h4 class="alert-heading">
              {{ alertDetail.title }}
            </h4>
            <div
              v-if="alertDetail.variant === 'danger'"
              class="alert-body"
            >
              <ul class="pl-2">
                <li
                  v-for="(item, index) in alertDetail.text"
                  :key="index"
                >
                  <template v-for="txt in item">
                    {{ txt }}
                  </template>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="alert-body"
            >
              {{ alertDetail.text }}
            </div>
          </b-alert>
        </template>

        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form>
            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="FAQ Title"
              rules="required"
            >
              <b-form-group
                label="FAQ Title"
                label-for="faq-title"
              >
                <b-form-input
                  id="faq-title"
                  v-model="newFaq.title"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="FAQ Title"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Company Name -->
            <validation-provider
              #default="validationContext"
              name="FAQ Answer"
              rules="required"
            >
              <b-form-group
                label="FAQ Answer"
                label-for="faq-answer"
              >
                <quill-editor
                  id="modalQuillFaq"
                  ref="myModalTextEditorFaq"
                  v-model="newFaq.message"
                  class="editor"
                  :options="editorOption2"
                />
                <div
                  id="quill-toolbar-faq"
                  class="d-flex border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-link" />
                </div>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow, BCol, BCard, BButton, BModal, VBModal, BCardText,
  BForm, BFormGroup, BFormInput,
  BFormInvalidFeedback, BAlert, VBTooltip, BFormFile,
  BCardHeader, BCardBody, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line no-unused-vars
import * as Emoji from 'quill-emoji'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useEmail from './useEmail'
import TicketMessageMainCard from './TicketMessageMainCard.vue'
import TicketMessageCard from './TicketMessageCard.vue'
import TicketRightSidebar from './TicketRightSidebar.vue'
import TicketAuthor from './module/TicketAuthor.vue'
import TicketSummary from './module/TicketSummary.vue'
import TicketPse from './module/TicketPse.vue'
import TicketFaq from './module/TicketFaq.vue'
import TicketEscalate from './module/TicketEscalate.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,

    // BSV
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BModal,
    BCardText,
    BLink,
    BFormInvalidFeedback,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BAlert,

    // 3rd Party
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
    quillEditor,

    // SFC
    TicketMessageMainCard,
    TicketMessageCard,
    TicketRightSidebar,
    TicketAuthor,
    TicketSummary,
    TicketPse,
    TicketFaq,
    TicketEscalate,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const toast = useToast()
    const { resolveLabelColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const userData = getUserData()

    const ticketData = {
      escalations: [],
      responses: [],
      author: {
        author_id: 0,
        company_name: 'No Information',
      },
      pse: {
        attachments: [],
      },
    }

    return {
      toast,
      userData,
      ticketData,

      refFormObserver,
      getValidationState,

      // UI
      perfectScrollbarSettings,

      // useTicket
      resolveLabelColor,
    }
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      today,
      required,
      email,
      goto: '/tickets',
      ticketnumber: '',
      interaksi: {
        name: 'Pilih salah satu',
        interaksi_id: 0,
      },
      reference: {},
      escalate_id: 0,
      resume: '',
      replyText: '',
      replyTemplate: '',
      counterInterval: false,
      intervalCheck: false,
      pseLink: false,
      templateAnswer: [],
      replyAttachData: [],
      files: [],
      attach: false,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar',
          'emoji-toolbar': true,
        },
      },
      editorOption2: {
        theme: 'snow',
        modules: {
          toolbar: '#quill-toolbar-faq',
        },
      },
      newFaq: {
        title: '',
        message: '',
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/tickets')
    } else {
      next(vm => {
        // eslint-disable-next-line no-param-reassign
        vm.goto = (from) ? from.path : '/tickets'
      })
    }
  },
  created() {
    this.ticketnumber = this.$route.params.id
    this.getReference()
    this.getData()
    this.counterInterval = setInterval(() => {
      this.intervalChecking()
    }, 1000 * 10)
  },
  destroyed() {
    clearInterval(this.counterInterval)
  },
  mounted() {
    this.observeHeight()
  },
  methods: {
    observeHeight() {
      const targetDiv = document.getElementById('reply-box')
      if (targetDiv) {
        const resizeObserver = new ResizeObserver((() => {
          const { offsetHeight } = targetDiv
          this.resizeBox(offsetHeight)
        }))

        resizeObserver.observe(targetDiv)
      }
    },
    resizeBox(height) {
      const div = document.getElementById('response-box')
      if (div) {
        div.style.maxHeight = `calc(100% - ${height + 70}px)`
      }
    },
    toBottom() {
      const targetDiv = document.getElementById('response-box')
      setTimeout(() => {
        targetDiv.scrollTop = targetDiv.scrollHeight
      }, 50)
    },
    viewAttach() {
      this.attach = (this.attach === false)
    },
    setEscalate(target) {
      this.escalate_id = target
      this.$refs.escalatedetail.show()
    },
    openModal(target) {
      this.$refs[target].show()
    },
    closeModal(target) {
      this.$refs[target].hide()
      this.getData()
    },
    updateData() {
      this.getData()
    },
    showAddFaqModal(value) {
      this.newFaq.message = value
      this.$refs['modal-faq'].show()
    },
    addKendala() {
      this.pseLink = true
      this.replyText = 'Untuk pengecekan lebih lanjut terkait kendala pendaftaran PSE Lingkup Privat, mohon mengisi form berikut:'
    },
    updateReply(msg) {
      this.replyText = msg
      this.$refs['modal-template'].hide()
    },
    goBack() {
      this.$router.push(this.goto)
    },
    getReference() {
      this.$http.post('ref/tx', true, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.reference = resp.data
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Refences',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post('/ticket/show', { ticket_number: this.ticketnumber }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.ticketData = resp.data.data
            this.templateAnswer = resp.data.reply

            if (this.ticketData.interaksi_id !== 0) {
              this.interaksi = {
                interaksi_id: this.ticketData.interaksi_id,
                name: this.ticketData.interaksi,
              }
            }

            this.intervalCheck = true
            // if (this.ticketData.channel === 'Whatsapp') {
            // }
            this.toBottom()
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Ticket Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.$router.push({ name: 'error-404' })
        })
    },
    intervalChecking() {
      if (this.intervalCheck && this.ticketData.status !== 'Closed') {
        if (this.ticketData.channel === 'Whatsapp') {
          this.getResponseUpdate()
        } else {
          this.getRegularUpdate()
        }
      }
    },
    getRegularUpdate() {
      this.$http.post('responses', { ticket_id: this.ticketData.ticket_id }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.data.length !== this.ticketData.responses.length) {
              this.ticketData.responses = resp.data.data
              this.getData()
              this.toBottom()

              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'New Reply Detected',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
            }
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Response Update',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getResponseUpdate() {
      this.$http.post('dcp/detail', { ticketCode: this.ticketData.subject }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            if (resp.data.responses.length !== 0) {
              this.getData()
            }
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Response Update',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveFaq() {
      document.getElementById('loading-bg').style.display = 'block'

      this.$http.post('/faq', this.newFaq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.alert = false
            this.$refs['modal-faq'].hide()

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'New FAQ Saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Saving FAQ',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    saveReply() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        ticket_id: this.ticketData.ticket_id,
        subject: 'OFFICER_REPLY',
        message: this.replyText,
        pse_link: this.pseLink,
      }
      if (this.pseLink) {
        params.subject = 'REQUEST_PSE'
      }
      this.$http.post('/response', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.ticketData.responses = resp.data.responses
            this.replyText = ''
            this.pseLink = false

            const status = true

            const promise2 = new Promise(resolve => {
              if (this.files.length !== 0) {
                for (let i = 0; i < this.files.length; i += 1) {
                  const formFile = new FormData()
                  formFile.append('response_id', resp.data.response_id)
                  formFile.append('attachment', this.files[i])

                  this.$http.post('/response/attach', formFile, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                    },
                  })
                    .then(fresp => {
                      if (fresp.data.success === true) {
                        this.ticketData.responses[this.ticketData.responses.length - 1].attachments.push(fresp.data.data)
                        resolve(true)
                      } else {
                        document.getElementById('loading-bg').style.display = 'none'

                        this.alert = true
                        this.alertDetail = {
                          variant: 'danger',
                          title: 'Error Saving Attachment Data',
                          text: (fresp.data.error !== '') ? fresp.data.error : '',
                        }
                        resolve(false)
                      }
                    })
                    .catch(error => {
                      document.getElementById('loading-bg').style.display = 'none'

                      this.alert = true
                      this.alertDetail = {
                        variant: 'danger',
                        title: 'Error Saving Attachment',
                        text: (error.response.data.errors !== '') ? error.response.data.errors : error,
                      }
                      resolve(false)
                    })
                }
              } else {
                resolve(true)
              }
            })

            Promise.all([promise2, status]).then(values => {
              if (values[0] && values[1]) {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Reply saved',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                // send notification after both process done
                this.$http.post('/response/send', {
                  response_id: resp.data.response_id,
                  reply_attach: this.replyAttachData,
                }, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`,
                  },
                })
                  .then(ntf => {
                    if (ntf.data.success === true) {
                      if (ntf.data.data.pse_code && this.ticketData.pse) {
                        this.ticketData.pse.request_code = ntf.data.data.pse_code
                      }
                      document.getElementById('loading-bg').style.display = 'none'
                      this.alert = false
                      this.attach = false
                      this.files = []
                      this.toBottom()
                      this.toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Reply Notification Sent',
                          icon: 'AlertTriangleIcon',
                          variant: 'success',
                        },
                      })
                    } else {
                      document.getElementById('loading-bg').style.display = 'none'
                      this.toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error sending response notification',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(() => {
                    document.getElementById('loading-bg').style.display = 'none'
                    this.toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Error sending response notification',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error saving response',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateStatus(mode) {
      this.$swal({
        title: (mode === 'accept') ? 'Accept This Ticket?' : 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          document.getElementById('loading-bg').style.display = 'block'
          const target = '/ticket/accept'
          const params = {
            ticket_id: this.ticketData.ticket_id,
          }
          if (mode === 'accept') {
            params.status_id = 2
          } else {
            params.status_id = 6
          }
          this.$http.post(target, params, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                document.getElementById('loading-bg').style.display = 'none'
                this.$swal({
                  icon: 'success',
                  title: 'Update Completed!',
                  text: 'Ticket status has been updated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              document.getElementById('loading-bg').style.display = 'none'
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Updating Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    closeTicket() {
      this.$swal({
        title: 'Close This Ticket?',
        html: '<p>you can add final summary to customer below</p>',
        input: 'textarea',
        inputLabel: 'Summary (max 100 karakter)',
        inputPlaceholder: 'Ticket Summary',
        inputAttributes: {
          maxlength: 100,
        },
        inputValidator: value => new Promise(resolve => {
          if (value.length < 101) {
            resolve()
          } else {
            resolve('The number of characters cannot be more than 100')
          }
        }),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Save and close!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const resume = this.$swal.getInput().value
        if (result.isConfirmed) {
          document.getElementById('loading-bg').style.display = 'block'
          const target = '/ticket/close'
          const params = {
            ticket_id: this.ticketData.ticket_id,
          }
          if (resume !== '') {
            params.resume = resume
          }
          this.$http.post(target, params, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                document.getElementById('loading-bg').style.display = 'none'
                this.$swal({
                  icon: 'success',
                  title: 'Update Completed!',
                  text: 'Ticket status has been updated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(errors => {
              document.getElementById('loading-bg').style.display = 'none'
              this.toast({
                component: ToastificationContent,
                props: {
                  title: `Error Updating Data : ${errors.response.data.message}`,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          this.getData()
          this.$swal.close()
        }
      })
    },
    updateFaqReply(data) {
      this.replyText = data
      this.toBottom()
    },
    updateFaqAttach(data) {
      this.replyAttachData = data
      this.toBottom()
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
@import "~quill-emoji/dist/quill-emoji.css";
</style>

<template>
  <b-card-text>
    <template v-if="alert">
      <b-alert
        :variant="alertDetail.variant"
        class="m-1"
        show
      >
        <h4 class="alert-heading">
          {{ alertDetail.title }}
        </h4>
        <div
          v-if="alertDetail.variant === 'danger'"
          class="alert-body"
        >
          <ul class="pl-2">
            <li
              v-for="(item, index) in alertDetail.text"
              :key="index"
            >
              <template v-for="txt in item">
                {{ txt }}
              </template>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="alert-body"
        >
          {{ alertDetail.text }}
        </div>
      </b-alert>
    </template>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
      >
        <!-- Full Name -->
        <validation-provider
          #default="validationContext"
          name="Full Name"
          rules="required"
        >
          <b-form-group
            label="Full Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="ticket.author.name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Full Name"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Company Name -->
        <validation-provider
          #default="validationContext"
          name="Company Name"
          rules="required"
        >
          <b-form-group
            label="Company Name"
            label-for="company-name"
          >
            <b-form-input
              id="company-name"
              v-model="ticket.author.company_name"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Company Name"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="ticket.author.email"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Email"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Phone -->
        <b-form-group
          label="Phone Number"
          label-for="phonenumber"
        >
          <b-form-input
            id="phonenumber"
            v-model="ticket.author.phonenumber"
            trim
            placeholder="Phone Number"
          />
        </b-form-group>

        <b-button
          variant="primary"
          size="sm"
          class="badge-round mb-2"
          @click="saveAuthor"
        >
          Save Customer Data
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-text>
</template>

<script>
import {
  BCardText, BAlert, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()
    return {
      toast,
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      required,
      email,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    saveAuthor() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        author_id: this.ticket.author.author_id,
        name: this.ticket.author.name,
        company_name: this.ticket.author.company_name,
      }

      if (this.ticket.author.email) {
        params.email = this.ticket.author.email
      }
      if (this.ticket.author.phonenumber) {
        params.phonenumber = this.ticket.author.phonenumber
      }

      this.$http.post('/author', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.ticket.author = resp.data.data
            this.alert = false

            this.$emit('dataupdate', this.ticket)
            this.$emit('closemodal', 'author')

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Customer Data Saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'

          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Found',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Saving Author Data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

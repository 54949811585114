<template>
  <b-card-text>
    <!-- BODY -->
    <template v-if="alert">
      <b-alert
        :variant="alertDetail.variant"
        class="m-1"
        show
      >
        <h4 class="alert-heading">
          {{ alertDetail.title }}
        </h4>
        <div
          v-if="alertDetail.variant === 'danger'"
          class="alert-body"
        >
          <ul class="pl-2">
            <li
              v-for="(item, index) in alertDetail.text"
              :key="index"
            >
              <template v-for="txt in item">
                {{ txt }}
              </template>
            </li>
          </ul>
        </div>
        <div
          v-else
          class="alert-body"
        >
          {{ alertDetail.text }}
        </div>
      </b-alert>
    </template>

    <validation-observer
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form>
        <div class="mb-50">
          <label>Tipe Interaksi</label><br>
          <v-select
            v-model="interaksi"
            :options="reference.interaksi"
            class="small"
            label="name"
            placeholder="Pilih salah satu"
            @input="updateStatusInteraksi"
          />
        </div>

        <!-- Kasus -->
        <validation-provider
          #default="validationContext"
          name="Kasus"
          rules="required"
        >
          <b-form-group
            label="Kasus"
            label-for="kasus"
          >
            <b-form-input
              id="kasus"
              v-model="ticket.kasus"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Kasus"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Full Name -->
        <validation-provider
          #default="validationContext"
          name="Penanganan"
          rules="required"
        >
          <b-form-group
            label="Penanganan"
            label-for="penanganan"
          >
            <b-form-input
              id="penanganan"
              v-model="ticket.penanganan"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Penanganan"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Full Name -->
        <validation-provider
          #default="validationContext"
          name="Keterangan"
        >
          <b-form-group
            label="Keterangan"
            label-for="keterangan"
          >
            <b-form-input
              id="keterangan"
              v-model="ticket.keterangan"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Keterangan"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-button
          variant="primary"
          size="sm"
          class="badge-round mb-2"
          @click="saveResume"
        >
          Save Summary Data
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-text>
</template>

<script>
import {
  BCardText, BAlert, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    interaksi: {
      type: Object,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    reference: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast()
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()
    return {
      toast,
      refFormObserver,
      getValidationState,
    }
  },
  data() {
    return {
      required,
      email,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  methods: {
    saveResume() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        ticket_id: this.ticket.ticket_id,
        interaksi_id: this.interaksi.interaksi_id,
        kasus: this.ticket.kasus,
        penanganan: this.ticket.penanganan,
        keterangan: this.ticket.keterangan,
      }

      this.$http.post('/ticket/resume', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.alert = false

            this.$emit('dataupdate', this.ticket)
            this.$emit('closemodal', 'summary')

            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Summary Saved',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.alert = true
          this.alertDetail = {
            variant: 'danger',
            title: 'Error Saving Summary',
            text: (error.response.data.errors !== '') ? error.response.data.errors : error,
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateStatusInteraksi() {
      this.ticket.interaksi_id = this.interaksi.interaksi_id
      this.ticket.interaksi = this.interaksi.name
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
